import React, { useState, useEffect } from 'react';
import Choice from './Choice.js';

export function Question(props) {

	const question = props.question;
	const [possibleChoices, setPossibleChoices] = useState(null);
	const [userChoice, setUserChoice] = useState(null);
	const callbackWhenQuestionAnswered = props.callback;

	// callback when a choice has been made
	useEffect(() => {
		if (userChoice) {
			let data = {
				"question": question,
				"answer": userChoice
			};
			callbackWhenQuestionAnswered(data);
		}
	}, [userChoice, question, callbackWhenQuestionAnswered]);

	// load question's answers based on question id
	useEffect(() => {
		fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/${question.id}`)
			.then(response => response.json())
			.then(json => {
				const answers = json.answers;
				// add neutral choice
				const neutralChoice = {
					id: "NEUTRAL_CHOICE_" + question.id,
					label: "Je ne suis vraiment pas capable de choisir entre les réponses ci-dessus.",
					for: "NONE",
					draw: answers.map(a => a.for)
				}
				answers.push(neutralChoice);
				setPossibleChoices(answers);
				return true;
			});
	}, [question]);

	return (
		<div className="col-sm-12 question">
			<div className='row mt-5'><p>Choisissez la proposition qui vous correspond le mieux :</p></div>
			<div className='row'><div className='col-sm-12'>
				{possibleChoices && possibleChoices.map((answer) => {
					return <Choice key={answer.id} answer={answer} onClick={() => setUserChoice(answer)} />
				})}
			</div></div>
		</div>
	);
}
