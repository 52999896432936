import React from 'react';
import Chart from "react-apexcharts";
 

function ResultHistogram(props) {
	
  const colors = props.persotypes.map(p => p.color.hex);

  const series= [
    {
			  name: "Score de préférence",
              data: props.scores
    }];
  const options = {
              chart: {
                height: 350,
                type: 'bar'
              },
              colors: colors,
              plotOptions: {
                bar: {
                  columnWidth: '90%',
                  distributed: true,
                }
              },
              dataLabels: {
                enabled: false
              },
              legend: {
                show: false
              },
              xaxis: {
                categories: props.persotypes.map(p => p.name),
                labels: {
                  style: {
                    colors: colors,
                    fontSize: '12px'
                  }
                }
              }
            };

      
          return (          
			<div id="histogram">
				<Chart options={options} series={series} type="bar" height={350} />
			</div>
          );
       
}

export default ResultHistogram;