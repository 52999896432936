import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ValuedAnswerLine } from './ValuedAnswerLine';

export function Question(props) {
	
	const question  = props.question;
	const nbAnswersPerPage = 8;
	const [likertResponses, setLikertResponses] = useState(null);
	const [items, setItems] = useState(null);
	const [values, setValues] = useState(null);
	const [currentItems, setCurrentItems] = useState(null);
	const [nextItemIndex, setNextItemIndex] = useState(0);
	const [errors, setErrors] = useState(null);
	const callbackWhenQuestionAnswered = props.callback;
	const labelRef = useRef(null);

	// callback when button is clicked
	const onSubmit = () => {
		backToTop();
		if (!validate()){
			return;
		}
		if (nextItemIndex < items.length){
			// new page of question with the next set of items
			const nextNext = nextItemIndex + nbAnswersPerPage;
			setCurrentItems(items.slice(nextItemIndex, nextNext));
			setNextItemIndex(nextNext);
			return;
		}
		let data = {
				"question": question,
				"answer": values.map((value, index)=>{
					return {
						answering : items[index],
						userValue : value
					};
				})
			};
		callbackWhenQuestionAnswered(data);
	};

	/**
	 * Validates that each item has been answered
	 */
	const validate = () => {
		let valid = true;
		let currentErrors = [...errors];
		for (let i = nextItemIndex - nbAnswersPerPage; i<nextItemIndex; i++){
			if (values[i]>0){
				currentErrors[i] = null;
			}else{
				valid = false;
				currentErrors[i] = "Veuillez choisir une réponse";
			}
		}
		setErrors(currentErrors);
		return valid;
	}

	// set range for a given item
	const onSelectValue = useCallback((item, val) => {
		setValues((vals) => {
			vals[item.index] = val;
			return vals;
		});
	},[]);
	
	// load question's answers based on question id
	useEffect(() => {
		const responses = question.scores;
		setLikertResponses(responses);
		fetch(`${process.env.REACT_APP_BACKEND_URL}/api/questions/${question.id}`)
		  .then(response => response.json())
		  .then(json => {
				const answers = json.answers;
				const defaultValues = [];
				answers.forEach(element => {
					defaultValues.push(-1);
				});
				setValues(defaultValues);
				setItems(answers);
				setCurrentItems(answers.slice(0,nbAnswersPerPage));
				let emptyErrors = [];
				for (let i = 0; i<answers.length; i++){
					emptyErrors.push(null);
				}
				setNextItemIndex(nbAnswersPerPage);
				setErrors(emptyErrors);
				return answers;
		  });
	  }, [question]);


	  /**
	   * Scroll back to the top of the page (or rather, to the question label)
	   */
	const backToTop = () =>
		window.scrollTo({
		top: labelRef.current.offsetTop,
		behavior: "smooth"
	});

	return(
		<div className="col-sm-12 question">
			<div className='row'>
				<div ref={labelRef} className="col-4"><span key="bfi-question-label" className='font-weight-bold'>{question.label}</span></div>
				<div className="col-8 mb-5">
					<div style={{display: 'flex', alignItems: 'center'}}>
						{likertResponses && likertResponses.map( (response, index) => {
								return <span key={`label-likert-${index}`} className='text-center mx-2' style={{flex: 1}}>{response.label}</span>
						})}
					</div>
				</div>
				<div className="w-100"/>
				{currentItems && errors && currentItems.map( (item) =>{
						const properties = {
							key : item.id,
							item : item,
							responses : likertResponses,
							value : values[item.index],
							onValueChange : onSelectValue,
							errorMessage : errors[item.index]
						};
						return <ValuedAnswerLine {...properties} />
				})}
				{currentItems && errors && 
					<button type="button" className="btn btn-primary" onClick={onSubmit}>Suivant</button>
				}
			</div>
		</div>
	);
}
