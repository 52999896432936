import Spinner from 'react-bootstrap/Spinner';
import UserEvaluation from '../UserEvaluation/UserEvaluation.js'
import ResultHistogram from './ResultHistogram.js';
import Narrative from './Narrative/Narrative.js';
import BfbpRadar from './BfbpRadar.js';

function Result(props) {

    const persotypes = props.persotypes;
    const bfbp = props.result.bfbp;
    const zScores = props.result.zScores;

    if (!Array.isArray(persotypes) || !persotypes.length) {
        // persotypes not available yet
        return <Spinner animation="border" role="status" />;
    } else {
        // persotypes is a non-empty array
        return (
            <div>
                <UserEvaluation formId={props.formId} />
                <div className="result">
                    <span className="font-weight-bold">Voici le résultat du test :</span>
                    <ResultHistogram scores={props.result.scores} persotypes={persotypes} />

                    <Narrative result={props.result} persotypes={persotypes} />

                    {props.result.zScores && <section className="mt-5">
                        <h2>Base PersoTypes</h2>
                        En plus du profil PersoTypes, ce test mesure également un certain nombre de traits de notre personnalité :
                        <div className="card">
                            <div className="card-header" id="big5Header" style={{ backgroundColor: "grey" }}>
                                <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#big5Body" aria-expanded="true" aria-controls="big5Body">
                                    <span className="font-weight-bold text-light text-decoration-none">Big Five</span>
                                </button>
                            </div>

                            <div id="big5Body" className="collapse" aria-labelledby="big5Header">
                                <div className="card-body">

                                    <BfbpRadar zScores={props.result.zScores} />
                                </div>
                            </div>
                        </div>
                    </section>}
                </div>
            </div>
        );
    }
}

export default Result;