import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import Question from '../Question/Dispatcher.js';
import Result from '../Result/Result.js';
import { Category } from '../Category/Category.js';


function App() {

	const [loading, setLoading] = useState(true);
	const [category, setCategory] = useState(null);
	const [questions, setQuestions] = useState([]);
	const [currentQuestion, setCurrentQuestion] = useState(null);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [result, setResult] = useState(null);
	const [answers, setAnswers] = useState([]);
	const [formId, setFormId] = useState(null);
	const [persotypes, setPersotypes] = useState([]);

	function questionAnswered(answer) {
		registerAnswer(answer);
		const nextQuestionIndex = currentQuestionIndex + 1;
		if (nextQuestionIndex < questions.length) {
			setCurrentQuestion(questions[nextQuestionIndex]);
			setCurrentQuestionIndex(nextQuestionIndex);
		} else {
			setCurrentQuestion(null);
		}
	}

	function registerAnswer(answer) {
		// change state
		setAnswers([...answers, answer]);
		// actually register answer
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(createDatabaseAnswer(answer))
		};
		const url = new URL(process.env.REACT_APP_BACKEND_URL + '/api/answers/' + formId);
		fetch(url, requestOptions);
	}

	function createDatabaseAnswer(answer) {
		const questionType = currentQuestion.type;
		switch (questionType) {
			case '2props':
			case '2propsC':
				return {
					questionId: answer.question.id,
					answerId: answer.answer.id
				};
			case 'bfi10_fr':
			case 'bfbp':
				const simplifiedAnswers = answer.answer.map((value, index) => {
					return {
						answering: value.answering.id,
						userValue: value.userValue
					};
				});
				return {
					questionId: answer.question.id,
					answers: simplifiedAnswers
				};
			default:
				const log = "Impossible de créer le résultat pour le type de question : " + questionType;
				console.log(log);
				return log;
		}
	}


	// Initial loading
	useEffect(() => {
		const url = new URL(process.env.REACT_APP_BACKEND_URL + "/api/questions");
		const params = {
			questionType: "2props",
			version: 1
		};
		url.search = new URLSearchParams(params).toString();
		// load questions
		fetch(url)
			.then(response => response.json())
			.then(json => {
				setQuestions(json.items);
				setCurrentQuestion(json.items[0]);
				return true;
			})
			.then(() => {
				setLoading(false);
				return true;
			})
			// create a form answer
			.then(() => {
				const requestOptions = {
					method: 'POST'
				};
				return fetch(process.env.REACT_APP_BACKEND_URL + '/api/answers', requestOptions);
			})
			.then(response => response.json())
			.then(json => {
				setFormId(json.id);
				return json.id;
			})
			// load bfbp question
			.then(() => {
				params.questionType = "bfbp";
				url.search = new URLSearchParams(params).toString();
				return fetch(url);
			})
			.then(response => response.json())
			.then(json => {
				setQuestions(q => {
					const array = [...q];
					json.items.forEach(item => array.push(item));
					return array;
				});
				return true;
			})
			// load consistency questions
			.then(() => {
				params.questionType = "2propsC";
				url.search = new URLSearchParams(params).toString();
				return fetch(url);
			})
			.then(response => response.json())
			.then(json => {
				setQuestions(q => {
					const array = [...q];
					json.items.forEach(item => array.push(item));
					return array;
				});
				return true;
			})
			// load persotypes
			.then(() => {
				return fetch(process.env.REACT_APP_BACKEND_URL + "/api/persotypes");
			})
			.then(response => response.json())
			.then(json => {
				setPersotypes(json.items);
			});
	}, []);

	// Register category
	useEffect(() => {
		if (category && formId) {
			// call backend to register category
			let augmentedCat = { ...category };
			if (window.location.search) {
				// add search params to category
				let search = window.location.search.substring(1);
				let urlParams = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
				augmentedCat.urlParams = urlParams;
			}
			console.log(`registering category ${JSON.stringify(augmentedCat)}`);
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(augmentedCat)
			};
			const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/answers/${formId}/category`);
			fetch(url, requestOptions);
		}
	}, [category, formId]);


	// compute results when registering the last answer
	useEffect(() => {
		if (persotypes.length > 0 && answers.length > 0 && answers.length === questions.length) {
			//TODO call backend to compute result	
			// create color map
			const colorMap = new Map();
			persotypes.forEach(type => {
				colorMap.set(type.color.name, type);
			});
			// init scores : persotypes
			const scores = [];
			const numberQuestions = questions.length;
			const defaultScore = 0;
			for (let i = 0; i < 7; i++) {
				scores.push(defaultScore);
			}
			// init scores : BFBP
			let bfbpScores;
			// update scores with each answer
			let consistencyScore = 0;
			let numberConsistencyQuestions = 0;
			const answerByQuestionId = new Map();
			for (let i = 0; i < numberQuestions; i++) {
				if (questions[i].type === 'bfbp') {
					bfbpScores = computeBfbpScores(answers[i].answer);
					continue;
				}
				// else, either 2props or 2propsC
				const color = answers[i].answer.for;
				if (color === "NONE") {
					// neutral answer : add 1 to score for each color in the draw
					answers[i].answer.draw.forEach((c) => {
						const index = colorMap.get(c).index;
						scores[index] += 1;
					});
				} else {
					// colored answer : add 3 to score of winning color
					const index = colorMap.get(color).index;
					scores[index] += 3;
				}
				if (questions[i].type === '2props') {
					// store answer for consistency check
					answerByQuestionId.set(answers[i].question.id, answers[i].answer);
				} else if (questions[i].type === '2propsC') {
					numberConsistencyQuestions++;
					const consistentColor = answerByQuestionId.get(answers[i].question.consistentWith).for;
					// consistency check
					if (color === consistentColor) {
						consistencyScore += 3;
					} else if (color === "NONE" || consistentColor === "NONE") {
						consistencyScore += 1;
					}

				}
			}
			// normalize the scores
			const bestScore = Math.max(...scores);
			for (let i = 0; i < 7; i++) {
				const score = scores[i] * 100 / bestScore;
				scores[i] = score === 0 ? 1 : Math.round(score);
			}
			if (numberConsistencyQuestions > 0) {
				consistencyScore = consistencyScore * 100 / (numberConsistencyQuestions * 3);
			}
			// construct result
			const res = {
				"scoresFromEnneag": scores,
				"consistency": consistencyScore,
				"bfbp": bfbpScores
			};
			// compute additional results and register result in database
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(res)
			};
			const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/api/answers/${formId}/result`);
			fetch(url, requestOptions)
				.then(response => response.json())
				.then(json => {
					// actually set the result to be able to show it
					setResult(json);
				});;
		}
	}, [answers, questions, persotypes, formId]);



	return (
		<Container>
			<Row>
				{process.env.REACT_APP_MODE === "STAGING" &&
					<p>STAGING MODE</p>
				}
				{!category &&
					<Category onSubmit={setCategory} />
				}
				{loading && category &&
					<Spinner animation="border" role="status" />
				}
				{currentQuestion && category &&
					<Question key={currentQuestion.id} question={currentQuestion} callback={questionAnswered} />
				}
				{result &&
					<Result persotypes={persotypes} result={result} formId={formId} />
				}
			</Row>
		</Container>
	);

}

function computeBfbpScores(bfbpAnswer) {
	const individualScores = bfbpAnswer.map((answer, index) => {
		const item = answer.answering;
		const axes = item.axes;
		const sign = item.sign;
		const value = answer.userValue;
		let score;
		if (sign > 0) {
			score = value;
		} else {
			score = (6 - value);
		}
		return { axes: axes, score: score };
	});
	const scoreMap = individualScores.reduce((map, currentValue) => {
		let axes = currentValue.axes;
		let updatedMap = new Map(map);
		axes.forEach(factor => {
			let score = currentValue.score;
			if (map.has(factor)) {
				const previousScore = map.get(factor);
				score += previousScore;
			}
			updatedMap.set(factor, score);
		});
		return updatedMap;
	}, new Map());
	const bfbpScores = Object.fromEntries(scoreMap);
	return bfbpScores;
}


export default App;
