import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const twoDigitsDecimalNumber = (n)=>{
    return n.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
}

export default function BfbpRadar(props) {

    const [bfbpVariables, setBfbpVariables] = useState(null);
    const zScores = props.zScores;
    const [radarOptions, setRadarOptions] = useState(null);
    const [series, setSeries] = useState(null);
    const [orderedFactors, setOrderedFactors] = useState(null);

    useEffect(() => {
        // load bfbp factors and limits
        if (!bfbpVariables) {
            fetch(process.env.REACT_APP_BACKEND_URL + "/api/bfbp")
                .then(response => response.json())
                .then(json => {
                    setBfbpVariables(json);
                    return json;
                });
        }
    }, [bfbpVariables]);

    useEffect(() => {
        // compute scores of factors for radar
        if (!bfbpVariables) {
            return;
        }
        const names = [];
        const values = [];
        const factors = [];
        let index = 0;
        for (const axis in bfbpVariables.bfbpFactors) {
            names[index] = axis;
            const components = bfbpVariables.bfbpFactors[axis].components;
            let big5 = 0;
            let i = 0;
            for (; i < components.length; i++) {
                big5 += zScores[components[i]];
            }
            big5 = big5 / i;
            values[index] = big5;

            let limitCategory = null;
            for (const limit in bfbpVariables.bfbpLimits) {
                let min = bfbpVariables.bfbpLimits[limit].min;
                let max = bfbpVariables.bfbpLimits[limit].max;
                if (min && max) {
                    if (big5 >= min && big5 <= max) {
                        limitCategory = limit;
                    }
                } else {
                    if (max && big5 < max) {
                        limitCategory = limit;
                    }
                    if (min && big5 > min) {
                        limitCategory = limit;
                    }
                }
            }
            let narrative = [...bfbpVariables.bfbpLimits[limitCategory].narrative];
            console.log(axis);
            for (let idx = 0; idx < narrative.length; idx++) {
                narrative[idx] = narrative[idx].replaceAll("$_{factor}", axis);
                narrative[idx] = narrative[idx].replaceAll("$_{positive}", bfbpVariables.bfbpFactors[axis].positive);
                narrative[idx] = narrative[idx].replaceAll("$_{negative}", bfbpVariables.bfbpFactors[axis].negative);
            }
            factors[index] = {
                name: axis,
                value: big5,
                limit: bfbpVariables.bfbpLimits[limitCategory].name,
                narrative: narrative
            };
            index++;
        }

        setSeries({
            name: 'Score',
            data: values
        });

        const options = {
            chart: {
                height: 350,
                type: 'radar',
            },
            title: {
                text: props.radarTitle
            },
            xaxis: {
                categories: names
            },
            yaxis: {
                labels: {
                    formatter: (value) => ''
                },
                tickAmount: 5,
                min: -3,
                max: 3
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            tooltip: {
                y: {
                    formatter : (value) => twoDigitsDecimalNumber(value)
                }
            }
        };
        setRadarOptions(options);
        // order by absolute value of the score descending
        factors.sort((a, b) => Math.abs(b.value) - Math.abs(a.value));
        setOrderedFactors(factors);
    }, [bfbpVariables, zScores, props.radarTitle]);




    return (<>
        {series && <div id="radar">
            <ReactApexChart options={radarOptions} series={[series]} type="radar" height={350} />
        </div>
        }
        {orderedFactors && orderedFactors.map(factor => <div key={factor.name} className="card">
            <div className="card-body">
                <h5 className="card-title">{factor.name}</h5>
                <p className="card-text">{bfbpVariables.bfbpFactors[factor.name].desc}</p>
                <p className="card-text">Votre score associé à {bfbpVariables.bfbpFactors[factor.name].withArt} est {factor.limit} ({twoDigitsDecimalNumber(factor.value)}).</p>
                {factor.narrative.map((line, index) => <p key={index} className="card-text">{line}</p>)}
                <p className="card-text">A noter que {bfbpVariables.bfbpFactors[factor.name].withArt} se subdivise en {bfbpVariables.bfbpFactors[factor.name].frenchComp
                    .map((domain, index) => {
                        return domain + " (" + twoDigitsDecimalNumber(zScores[bfbpVariables.bfbpFactors[factor.name].components[index]]) + ")";
                    })
                    .join(" et ")}.</p>
            </div>
        </div>
        )}
    </>);
}