import React from 'react';

import {Question as Q2props} from './2props/Question';
import {Question as Qbfi} from './bfi/Question';

function Question(props) {

    const questionType = props.question.type;

    switch (questionType){
        case '2props':
        case '2propsC':
            return <Q2props {...props} />;
        case 'bfi10_fr':
        case 'bfbp':
            return <Qbfi {...props} />;
        default:
            return <div><p>Type de question inattendu : {questionType}</p></div>
    }
}

export default Question;