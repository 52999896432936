import React from 'react';
import Likert from 'react-likert-scale';


export function ValuedAnswerLine(props){

    const value = props.value;
    const error = props.errorMessage;
    const callbackWhenValueChanged = props.onValueChange;
    const item = props.item;
    const responses = props.responses.map((resp) => {
        let response = {
            value : resp.value,
            text: ""
        };
        if (value === resp.value){
            response.checked = true;
        }
        return response;
    });

    return (
        <>
            <div className="col-4">{props.item.label}</div>
            <div className="col-8">
                {error && 
                <span className="text-danger">{error}</span>
                }
                <Likert responses={responses} id={`likert-${item.id}`} onChange={(val)=>callbackWhenValueChanged(item, val.value)}/>
            </div> 
            <div className="w-100"></div>        
        </>
    );
    
}