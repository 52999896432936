import React from 'react';
import './Narrative.css';

/**
 * Creates a card representing a persotype
 * @param {persotype} props
 * @returns
 */
export function PersotypeCard(props) {

	/**
	 * Returns a RGBA string of the given RGB object
	 * @param {string} rgbObject The color with r, g, b, a properties.
	 */
	function toRgbaString(rgbObject) {
		return `rgba(${rgbObject.r}, ${rgbObject.g}, ${rgbObject.b}, ${rgbObject.a})`;
	}

	const headerId = "heading" + props.persotype.index;
	const headerStyle = { backgroundColor: toRgbaString(props.persotype.color.rgb) };
	const collapsibleContentId = "collapse" + props.persotype.index;

	return (
		<div className="card">
			<div className="card-header" id={headerId} style={headerStyle}>
				<button className={"btn btn-link btn-block text-left" + ((props.open===true) ? "" : " collapsed")} type="button" data-toggle="collapse" data-target={"#" + collapsibleContentId} aria-expanded="true" aria-controls={collapsibleContentId}>
					<span className="font-weight-bold text-light text-decoration-none">{props.persotype.name}</span>
				</button>
			</div>

			<div id={collapsibleContentId} className={"collapse" + ((props.open===true) ? " show" : "")} aria-labelledby={headerId}>
				<div className="card-body">
					<PersotypeCardBodyContent persotype={props.persotype} />
				</div>
			</div>
		</div>
	);
}
/**
 * Creates the body content of a persotype card.
 * @param {persotype} props
 */
function PersotypeCardBodyContent(props) {
	return (
		<div>
			<div className="my-2">
				<span className="font-weight-bold">{props.persotype.adjectives.join(" - ")}</span>
			</div>
			<div className="my-2">
				<span className="font-weight-bold">Besoin psychologique</span>
				<p>{props.persotype.needs}</p>
			</div>
			<div className="my-2">
				<span className="font-weight-bold">Comportement</span>
				{props.persotype.behavior.map((paragraph, index) => <p key={`behavior-${index}`}>{paragraph}</p>)}
			</div>
		</div>);
}
