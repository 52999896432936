import React from 'react';
import Button from 'react-bootstrap/Button';

export default function Choice(props){

    const answer = props.answer;

    return (
        <Button variant="outline-dark btn-block" onClick={props.onClick} >
                {answer.label}
        </Button>
    );
}