import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

const codeRegexp = new RegExp("^[A-Z]{3}[0-9]+[A-Z]$");

const lastTested = { code: null, test: true };

/**
 * Tests if the given code is a valid one.
 * @param {string} code 
 * @returns 
 */
const testCode = async (code) => {
    // do not re-test if the value has not changed
    if (lastTested.code === code) {
        return Promise.resolve(lastTested.test);
    }

    if (!code || code.toLowerCase() === 'test') {
        Object.assign(lastTested, { "code": code, "test": true });
        return Promise.resolve(true);
    }
    // code is group trigram + numerical sequence + one letter => minimal length is 5       
    if (code.length < 5) {
        Object.assign(lastTested, { "code": code, "test": false });
        return Promise.resolve(false);
    }
    if (!codeRegexp.test(code)) {
        Object.assign(lastTested, { "code": code, "test": false });
        return Promise.resolve(false);
    }

    // the code actually has the format for a test code, we can check if it is valid
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "code": code })
    };
    return fetch(process.env.REACT_APP_BACKEND_URL + "/api/codes/validate", requestOptions)
        .then(res => res.json())
        .then((data) => {
            if (data.valid === true) {
                Object.assign(lastTested, { "code": code, "test": true });
                return true;
            }
            console.log("Code non valide : " + data.validationError);
            Object.assign(lastTested, { "code": code, "test": false });
            return false;
        });
}

const DisplayingErrorMessagesSchema = Yup.object().shape({
    gender: Yup.string().required('Veuillez choisir une réponse'),
    role: Yup.string().required('Veuillez choisir une réponse'),
    code: Yup.string().test("test-code-valid", "Ce code n'est pas valide", testCode)
});


export function Category(props) {


    function handleSubmit(values) {
        props.onSubmit(values);
    }

    return (
        <div>
            <p className="my-5"> Ce test est gratuit. Votre profil PersoTypes sera affiché à la fin des questionnaires. Aucun mail ne vous sera demandé.
                Vos réponses sont sauvegardées à des fins purement statistiques mais nous ne gardons aucune information permettant de vous identifier.</p>
            <Formik
                initialValues={{
                    gender: '',
                    role: '',
                    prof: '',
                    code: ''
                }}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={handleSubmit}>
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <div id="gender-radio-group" className="form-label">Vous êtes :</div>
                        <div role="group" aria-labelledby="gender-radio-group" className="form-check form-check-inline mb-3">
                            {errors.gender && touched.gender && <span className="text-danger">{errors.gender}</span>}
                            <Field type="radio" name="gender" value="homme" id="gender-homme" className="form-check-input" />
                            <label className="form-check-label mr-4" htmlFor="gender-homme">
                                un homme
                            </label>
                            <Field type="radio" name="gender" value="femme" id="gender-femme" className="form-check-input" />
                            <label className="form-check-label mr-4" htmlFor="gender-femme">
                                une femme
                            </label>
                            <Field type="radio" name="gender" value="autre" id="gender-autre" className="form-check-input" />
                            <label className="form-check-label mr-4" htmlFor="gender-autre">
                                autre identité de genre
                            </label>
                        </div>

                        <div id="role-radio-group">Vous passez ce test en tant que :</div>
                        <div role="group" aria-labelledby="role-radio-group" className="form-check form-check-inline mb-3">
                            {errors.role && touched.role && <span className="text-danger">{errors.role}</span>}
                            <Field type="radio" name="role" value="soigné" id="role-soigne" className="form-check-input" />
                            <label className="form-check-label mr-4" htmlFor="role-soigne">
                                soigné
                            </label>
                            <Field type="radio" name="role" value="soignant" id="role-soignant" className="form-check-input" />
                            <label className="form-check-label mr-4" htmlFor="role-soignant">
                                soignant
                            </label>
                            <Field type="radio" name="role" value="autre" id="role-autre" className="form-check-input" />
                            <label className="form-check-label mr-4" htmlFor="role-autre">
                                ni l'un ni l'autre (vous êtes aussi les bienvenus)
                            </label>
                        </div>

                        <div className='mb-3'>
                            <label htmlFor="prof">Quelle est votre profession ?</label>
                            <Field className="form-control" type="text" id="prof" name="prof" placeholder="oncologue / retraité ancien agriculteur / plombier / etc." />
                        </div>

                        <div className='mb-3'>
                            <label htmlFor="code">Si vous êtes ici dans le cadre d'une étude, quel est le code qui vous a été remis ?</label>
                            <Field className="form-control" type="text" id="code" name="code" />
                            {errors.code && touched.code && <span className="text-danger">{errors.code}</span>}
                        </div>

                        <button className="btn btn-primary" type="submit" disabled={isSubmitting}>Continuer</button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}