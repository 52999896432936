import React from 'react';
import { PersotypeCard } from './PersotypeCard';

function Narrative(props) {

	const best = props.persotypes[props.result.best];
	const hasMajors = (props.result.majors.length > 0);
	const hasMinors = (props.result.minors.length > 0);
	return (
		<div className="result narrative">
			<div className="result preference mt-5">
				<p><span className="font-weight-bold">PersoType Principal</span></p>
				<p>Votre préférence est le type {best.name}.</p>
				<PersotypeCard persotype={best} open={true} key={best.index} />
			</div>
			{hasMajors &&
				<Majors majors={props.result.majors} persotypes={props.persotypes} />
			}
			{hasMinors &&
				<Minors minors={props.result.minors} persotypes={props.persotypes} />
			}
		</div>
	);
}

function Majors(props) {
	const plural = props.majors.length > 1;
	return (
		<div className="result majors mt-5">
			<p><span className="font-weight-bold">Autres PersoTypes Majeurs</span></p>
			{plural ?
				<p>Vous avez également obtenu une préférence élevée pour les types {props.majors.map((major, index) => {
					let prefix = "";
					if (index > 0 && index < props.majors.length - 1) {
						prefix = ", ";
					} else if (index === props.majors.length - 1) {
						prefix = " et ";
					}
					return <>{prefix}{props.persotypes[major].name}</>;
				})}.</p>
				:
				<p>Vous avez également obtenu une préférence élevée pour le type {props.persotypes[props.majors[0]].name}.</p>
			}
			{props.majors.map((i) => {
				return <PersotypeCard persotype={props.persotypes[i]} key={i} />;
			})}
		</div>
	);

}

function Minors(props) {
	const plural = props.minors.length > 1;
	return (
		<div className="result minors mt-5">
			<p><span className="font-weight-bold">PersoTypes Mineurs</span></p>
			<p>Connaître son ou ses PersoTypes mineurs est également important.
				Ils correspondent souvent aux PersoTypes que nous avons du mal à identifier ou avec lesquels nous avons du mal à interagir.</p>
			{plural ?
				<>
					<p>Votre préférence est faible pour les types {props.minors.map((minor, index) => {
						let prefix = "";
						if (index > 0 && index < props.minors.length - 1) {
							prefix = ", ";
						} else if (index === props.minors.length - 1) {
							prefix = " et ";
						}
						return <>{prefix}{props.persotypes[minor].name}</>;
					})}.
					</p>
					<p>A noter que ceci ne veut <u>pas</u> dire que vous n'êtes pas {props.minors.map((minor, index) => {
						let prefix = "";
						if (index > 0 && index < props.minors.length - 1) {
							prefix = ", ";
						} else if (index === props.minors.length - 1) {
							prefix = " ou ";
						}
						return <>{prefix}{props.persotypes[minor].name.toLowerCase()} (dans le sens “{props.persotypes[minor].opposite}”)</>;
					})}.
						Les axes correspondant à ces PersoTypes semblent juste peu structurants pour vous.
					</p>
				</>
				:
				<p>Vous avez une préférence faible pour le PersoType {props.persotypes[props.minors[0]].name}.
					A noter que ceci ne veut <u>pas</u> dire que vous n'êtes pas {props.persotypes[props.minors[0]].name.toLowerCase()} dans le sens “{props.persotypes[props.minors[0]].opposite}”.
					Les axes correspondant à ce PersoType semblent juste peu structurants pour vous.</p>
			}
			{props.minors.map((i) => {
				return <PersotypeCard persotype={props.persotypes[i]} key={i} />;
			})}
		</div>
	);

}

export default Narrative;