import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import StarRating from './StarRating';
import './UserEvaluation.css';

export default function UserEvaluation(props){

    const [submitted, setSubmitted] = useState(false);
    const [evaluationFinished, setEvaluationFinished] = useState(false);
   
    return (
            <div className="my-5">
                {evaluationFinished ? "" :
                    <Card>
                        <Card.Body>              
                            {submitted ? 
                            <AfterSubmitContent onEvaluationFinished={() => setEvaluationFinished(true)}/> : 
                            <UserEvaluationContent onSubmitted={() => setSubmitted(true)} formId={props.formId}/>}
                        </Card.Body>
                    </Card>
            }
            </div>
    );
}

function UserEvaluationContent(props){

    const [hoverRating, setHoverRating] = useState(0);
    const [selectedRating, setSelectedRating] = useState(5);
    const [submitting, setSubmitting] = useState(false);
    const [comment, setComment] = useState("");

    function handleSubmit(){
        if (submitting){
            // already submitting, do nothing
            return;
        }
        // register evaluation in database
        const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ 
				"rating" : selectedRating,
                "comment" : comment
			 })
		};
		fetch(`${process.env.REACT_APP_BACKEND_URL}/api/answers/${props.formId}/eval`, requestOptions);

        setSubmitting(false);
        props.onSubmitted();
    }

    return <>
        <p><span className="font-weight-bold">Votre avis nous intéresse : qu'avez-vous pensé de ce test et du résultat ci-dessous ?</span></p>
        <Form>
            <StarRating starCount={5} hover_rating={hoverRating} selected_rating={selectedRating} 
                handleOnClick={setSelectedRating}
                handleOnMouseOver={setHoverRating}
                handleOnMouseLeave={() => setHoverRating(0)}
            />
            <Form.Control as="textarea" rows={3} placeholder="Votre commentaire libre" value={comment} onChange={(e) => setComment(e.target.value)} />
            <Button variant="primary" onClick={handleSubmit}>
            {submitting ? <Spinner animation="border" role="status" /> : "Envoyer votre avis"}
            </Button>
        </Form>
    </>;

}

function AfterSubmitContent(props){

    const [spanClass, setSpanClass] = useState("user-evaluation");
    const onFinish = props.onEvaluationFinished;

    useEffect(() => {
        const timer = setTimeout(() => setSpanClass("user-evaluation fadeout"), 1000);
        return () => clearTimeout(timer);
      }, []);

      useEffect(() => {
        if (spanClass.endsWith("fadeout")){
            const timer = setTimeout(() => onFinish(), 1000);
            return () => clearTimeout(timer);
        }
      }, [spanClass, onFinish]);

    return <>
        <p><span className={"font-weight-bold "+spanClass}>Merci pour votre retour !</span></p>
    </>;
}
